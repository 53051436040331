/* global sessionStorage */
/* global localStorage */
import axios from 'axios'
const instance = axios.create({
    timeout: 1000 * 15,
  //  baseURL: process.env.NODE_ENV === 'production' ? 'https://api.shanzhuan.top/' : 'https://api.shanzhuan.top/'
  baseURL: process.env.NODE_ENV === 'production' ? 'https://api.shanzhuan.top/' : 'http://m.diaoqianyaner.com.cn/'
  // baseURL: process.env.NODE_ENV === 'production' ? 'https://m.ssyxgs.top/' : 'http://192.168.1.252:8088'
})
// http://47.97.49.234:8088/

// let count = 0

instance.interceptors.request.use(config => {
  // count++
  if (config.url.indexOf('sms') !== -1 || config.url.indexOf('users') !== -1) {
    config.headers['sourceApp'] = 'dqy3'
  }

  // const token = sessionStorage.getItem('token')
  // const id = localStorage.getItem('user_id')
  // const timestamp = sessionStorage.getItem('timestamp')
    const ost = localStorage.getItem('ost')
  // if (token) {
  //   config.headers['token'] = token
  // };
  // if (id) {
  //   config.headers['id'] = id
  // }
  // if (timestamp && timestamp !== 'undefined') {
  //   config.headers['timestamp'] = timestamp
  // }
    if (!!ost) {
        config.headers['ost'] = ost
    }
  return config
})

instance.interceptors.response.use((response) => {
  // reduceCount()
  return response
}, (error) => {
    if(error.message === 'Network Error'){
        Promise.reject(error)
    }else if(error.message.indexOf('timeout') !== -1){
        Promise.reject(error)
    }
  // reduceCount()
  return Promise.reject(error.response)
})

// function reduceCount () {
//   count--
//   if (count === 0) {
//     Indicator.close()
//   }
// }

instance.defaults.headers.post.Accept = 'application/json'
instance.defaults.headers.post['Content-Type'] = 'application/json'

export default instance
