import React from 'react'
import './listItem.scss';

const list = [
    {
        app: {
            image_url: require('../../assets/1.jpg')
        },
        app_name: '皇***',
        num: '1000+',
        reward: '120'
    }, {
        app: {
            image_url: require('../../assets/2.jpeg')
        },
        app_name: '智***',
        num: '498',
        reward: '120'
    }, {
        app: {
            image_url: require('../../assets/3.jpeg')
        },
        app_name: '5***',
        num: '1000+',
        reward: '120'
    }, {
        app: {
            image_url: require('../../assets/4.jpeg')
        },
        app_name: '贝***',
        num: '98',
        reward: '120'
    }, {
        app: {
            image_url: require('../../assets/5.jpeg')
        },
        app_name: '安***',
        num: '1000+',
        reward: '120'
    }, {
        app: {
            image_url: require('../../assets/6.jpg')
        },
        app_name: '魔***',
        num: '773',
        reward: '120'
    }
]

export default class ListItem extends React.Component {
    constructor(props) {
        super(props)
    }

    click(item) {
        this.props.handleClick(item)
    }

    formatName(str) {
        return str.substr(0, 1) + new Array(3).join('*')
    }

    render() {
        return (
            <div className="itemContainer">
                {list.map((item, index) => {
                    return (
                        <div key={index} className="itemWrapper">
                            <div className="right">
                                <img className="img" width="52" height="52" src={item.app.image_url} />
                                <div className="type">
                                    <div className="name_status">
                                        <div className="font ">{this.formatName(item.app_name)}</div>
                                    </div>
                                    <div className="small">剩余{item.num}份</div>
                                </div>
                            </div>
                            <span className="touch_">+ <span className="touch">{(item.reward / 100).toFixed(2)}</span> 元</span>
                        </div>
                    )
                })}

            </div>
        )
    }
}