import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "@babel/polyfill/noConflict";
import {HashRouter} from 'react-router-dom'
import Routes from './router/index';
import * as serviceWorker from './serviceWorker';
import './utils/google'
// import './utils/baidu'
import './assets/fonts/iconfont.css'

ReactDOM.render(
    <HashRouter>

        <Routes/>

    </HashRouter>, document.getElementById('root')
);
serviceWorker.unregister();
