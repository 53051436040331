import React, { useEffect, useState } from "react";
import axios from "../../netUtils/axios";

import copy from "copy-to-clipboard/index";

import "./index.scss";

const ipArray = [
  "47.111.73.156:18080",
  // "121.41.91.93:18080",
  "47.110.70.116:18080",
  // "120.26.233.25:18080",
  // "47.111.83.203:18080",
  "218.244.145.208:18080",
  "120.27.224.133:18080",
  // '121.40.106.106:18080', 链接失效
  // '120.27.224.133:18080'  链接失效
];

const index = (props) => {
  const [appInfo, setAppInfo] = useState({});
  useEffect(() => {
    axios.get(`ios_package_info`).then((res) => {
      setAppInfo(res.data);
    });
  }, []);

  const download = (e) => {
    let userId = window.localStorage.getItem("userId");
    let key = localStorage.getItem("key");
    if (userId) {
      copy(`%%cq=${userId}-${key}%%`);
    }
    if (true) {
      let ip = ipArray[Math.floor(Math.random() * ipArray.length)];
      window._hmt.push(["_trackEvent", "下载", "点击"]);
      window.ga("send", "event", "下载", "click", "页面浏览");
      window.localStorage.setItem(
        "assistant",
        // `https://apps.apple.com/cn/app/id${appInfo.app_id}`
        `https://apps.apple.com/cn/app/id6450917625`

      );
      window.localStorage.setItem(
        "bundleId",
        appInfo.bundle_id.replace(/\./g, "")
      );
      // window.location.href = `http://${ip}/try?gs_url=https://apps.apple.com/cn/app/id${appInfo.app_id}&return_url=https://www.shanzhuan.top`;
      window.location.href = `http://${ip}/try?gs_url=https://apps.apple.com/cn/app/id6450917625&return_url=https://www.shanzhuan.top`;

    } else {
      window.location.href = "https://testflight.apple.com/join/0oQcibJX";
    }
    this.props.close(e);
  }

  return (
    <div
      id="IosPackage"
      onClick={() => {
        let userId = window.localStorage.getItem("userId");
        let key = localStorage.getItem("key");
        copy(`%%cq=${userId}-${key}%%`);
      }}
    >
      <header>
        <img
          onClick={() => {
            props.history.go(-1);
          }}
          height="20"
          src={require("../../assets/v2/back-black.png")}
          alt=""
        />
        <span>下载试玩助手</span>
      </header>

      <div
        className="tfbj"
        onClick={download}
      >
        <div className="box1">
          {/* <img src={appInfo.img_url} /> */}
          <img src={'https://is1-ssl.mzstatic.com/image/thumb/Purple116/v4/da/70/c6/da70c645-cf25-48f9-21f6-ba10f39945fc/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/180x180bb.png'} alt="" />
          <div className="box2">
            {/* <span>{appInfo.app_name}</span> */}
            <p>试玩助手</p>
            <span style={{ fontSize: 11, color: 'rgba(65, 75, 90, 0.66)' }}>下载后允许权限即可开始</span>
          </div>
        </div>
      </div>
      < img
        alt=""
        onClick={() => {
          let userId = window.localStorage.getItem("userId");
          let key = localStorage.getItem("key");
          if (userId) {
            copy(`%%cq=${userId}-${key}%%`);
          }
          // window.location.href = appInfo.schema + "://";
          window.location.href = "comshoubanpcom://";
        }}
        src={require("../../assets/v3/Frame 208.png")}
      />
    </div>
  );
};

export default index;
