import React from 'react'
import {Route, Switch} from 'react-router-dom'
import Home from '../views/Home/home'
import Login from '../views/Login/login'
import Attempt from '../views/Attempt/attempt'
import Call from '../views/Call/call'
import Install from '../views/Call/installMiddlePage'
import TF from '../views/TF'
import IosPackage from '../views/iosPackage'

const Routes = () => {
    return (
        <React.Fragment>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/login/:id" component={Login}/>
                <Route path="/attempt" component={Attempt}/>
                <Route path="/call" component={Call} />
                <Route path="/installMidPage" component={Install} />
                <Route path='/TF/:url' component={TF} />
                <Route path='/iosPackage' component={IosPackage} />
                <Route path="/*" component={Login} />
            </Switch>
        </React.Fragment>
    )
}
export default Routes
