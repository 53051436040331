import React from 'react'
import '../../views/Attempt/attempt.scss';
export default class Trust extends React.Component {
    constructor (props) {
        super(props)
        this.state={
            seconds:6,
            content:'信任助手',
            click: false
        }
    }
    componentDidMount(){
        this.timer =  setInterval(()=>{
            this.setState({
                seconds:this.state.seconds-1,
            },()=>{

                if (this.state.seconds === 0) {
                    clearInterval(this.timer);
                    this.setState({
                        seconds:6,
                        content: `信任助手`,
                        click: true
                    })
                } else {
                    this.setState({
                        content: `信任助手(${this.state.seconds}s)`
                    })

                }
            })
        },1000)
    }
    componentWillUnmount(){
        clearInterval(this.timer);
    }
    render() {
        return (
            <div className="attemptmask">
                <div className="animated zoomIn dialog" style={{
                    paddingTop: 20,
                    paddingBottom: 34,
                    paddingLeft: 18,
                    paddingRight: 18,
                    height:395
                }}>
                    <img onClick={(e) => {
                        this.props.close_(e)
                    }} className="close" src={require('../../assets/v2/close_tiny.png')}/>
                    <div className="install_key">
                        <div className="install_key_title">设置信任助手</div>
                        <div className="install_step">
                            <div>
                                <span>1</span>
                                <p>点击“信任助手”</p>
                            </div>
                            <p className="intro">或在手机设置中打开 &gt; 通用 &gt; 设备管理</p>
                            <div style={{width: 215, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                <span>2</span>信任“{this.props.signer}”
                            </div>
                            <a href="https://statics.diaoqianyaner.com.cn/guide.mp4">查看演示视频</a>
                            <div>
                                <span>3</span>
                                <p>点击“打开助手”</p>
                            </div>
                            <p className="intro">如启动失败，请返回桌面点击APP</p>
                            <div>
                                <span>4</span>如安装失败,请卸载后重试
                            </div>
                        </div>
                    </div>
                    <div className={this.state.click?'btn-open':'btn_default'} onClick={()=>{this.props.trust_(this.state.click)}}>{this.state.content}</div>
                    <div className="btn-open" onClick={this.props.openApp}>打开助手</div>
                </div>
            </div>
        )
    }
}