import React from "react";
import "../views/Attempt/attempt.scss";
import copy from "copy-to-clipboard/index";
import axios from "../netUtils/axios";
const ipArray = [
  "47.111.73.156:18080",
  // "121.41.91.93:18080",
  "47.110.70.116:18080",
  // "120.26.233.25:18080",
  // "47.111.83.203:18080",
  "218.244.145.208:18080",
  "120.27.224.133:18080",
  // '121.40.106.106:18080', 链接失效
  // '120.27.224.133:18080'  链接失效
];
export default class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "qsjs",
      install: false,
      appInfo: {},
    };
  }

  componentDidMount() {
    if (true) {
      axios.get(`ios_package_info`).then((res) => {
        this.setState({
          appInfo: res.data,
        });
      });
    }

    // let type = this.random(app, rate)
  }

  componentWillUnmount() {}

  random(arr1, arr2) {
    var sum = 0;
    var factor = 0;
    var random = Math.random();
    for (var x = arr2.length - 1; x >= 0; x--) {
      sum += arr2[x];
    }
    random *= sum;
    for (var t = arr2.length - 1; t >= 0; t--) {
      factor += arr2[t];
      if (random <= factor) {
        return arr1[t];
      }
    }
    return null;
  }

  download(e) {
    this.props.history.push('/iosPackage')
  }

  render() {
    return (
      <div className="attemptmask">
        {true ? (
          <div
            className="animated zoomIn dialog_"
            style={{ position: "relative", marginBottom: 81 }}
          >
            <div
              className="app-info"
              style={{ width: "100%" }}
              onClick={(e) => {
                this.download(e);
              }}
            >
              <img src={this.state.appInfo.img_url} />
              <span className="name">{this.state.appInfo.app_name}</span>
            </div>
            <img
              src={require("../assets/v3/Group 208.png")}
              width="240px"
              style={{
                position: "absolute",
                bottom: -63,
                left: "50%",
                transform: "translateX(-50%)",
              }}
              onClick={() => {
                let userId = window.localStorage.getItem("userId");
                let key = localStorage.getItem("key");
                if (userId) {
                  copy(`%%cq=${userId}-${key}%%`);
                }
                window.location.href = this.state.appInfo.schema  + "://";
              }}
              alt=""
            />
          </div>
        ) : (
          <img
            className="animated zoomIn"
            onClick={(e) => {
              this.download(e);
            }}
            style={{ width: "75%" }}
            src={require("../assets/download/ppa.png")}
          />
        )}
      </div>
    );
  }
}
