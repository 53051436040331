import React ,{useState, useEffect, useRef}from 'react'
import './login.scss'
import axios from '../../netUtils/axios'
import { Toast } from 'antd-mobile';
export default class Login extends React.Component {
    constructor(props){
        super(props)
        this.state={
            phoneNum:'',
            code:'',
            dlgTipTxt: '获取验证码',
            time: 59,
            title: '验证码登录',
            disabled: false,
            loginDisable: false
        }
    }
    componentDidMount () {
        this.setState({
            title:window.localStorage.getItem('title')

        })
    }
    componentWillUnmount() {
        this.setState({
            dlgTipTxt: '获取验证码',
            time: 59,
            disabled: false
        })
        clearInterval(this.timer);
    }
    Interval() {
        let that = this
        this.timer = setInterval(() => {
            that.setState({
                time: this.state.time - 1,
                dlgTipTxt: `${this.state.time}s后重新发送`,
                disabled: true
            }, () => {
                if (this.state.time === 0) {
                    clearInterval(this.timer);
                    this.setState({
                        dlgTipTxt: '获取验证码',
                        time: 59,
                        disabled: false
                    })
                }
            })
        }, 1000)
    }
    TCaptcha () {
        if (this.state.disabled) {
            return false
        }
        if (!/^1[2|3|4|5|6|7|8|9][0-9]{9}$/.test(this.state.phoneNum)){
            return Toast.fail('请输入正确的手机号', 1.5)
        }
        let that = this
        // eslint-disable-next-line no-undef
        var captcha = new TencentCaptcha('2048753709', function(res) {
            axios.get(`/sms/captcha?ticket=${res.ticket}&randstr=${res.randstr}&mobile=${that.state.phoneNum}`).then((res)=>{
                that.sms()
            },(fail) => {
                Toast.info('验证失败',1.5)
            })
        });
        captcha.show(); // 显示验证码
    }
    sms() {
        axios.get(`/sms/v3?mobile=${this.state.phoneNum}`).then((res) => {
            if (res.status === 200) {
                Toast.success('发送成功', 1);
                this.Interval()
            } else{
                Toast.fail('发送失败', 1);
                clearInterval(this.timer);
            }
        }).catch(e=>{
            switch (e.data.code) {
                case 9998:
                    Toast.info('手机号码格式错误', 1);
                    break
                case 1007:
                    Toast.info('此手机号码已绑定,请绑定其他手机号码', 1);
                    break
                case 1901:
                    //1901 人机验证失败
                    Toast.info('发送短信失败，请稍后重试', 1)
                    break
                case 1902:
                    Toast.info('此手机号码已绑定,请绑定其他手机号码', 1)
                    break
                case 1903:
                    Toast.info('发送短信失败，请稍后重试', 1)
                    break
                case 1904:
                    Toast.info('此手机号码已绑定,请绑定其他手机号码', 1)
                    break
                case 1905:
                    Toast.info('验证码错误', 1)
                    break
                default:
                    Toast.info('网络异常请重试', 1)
            }
            clearInterval(this.timer);
        })
    }
    login() {
        if (this.state.loginDisable) {
            return false
        }
        if (this.state.phoneNum === '') {
            return Toast.fail('请输入正确的手机号', 1.5)
        }
        if (!/^1[2|3|4|5|6|7|8|9][0-9]{9}$/.test(this.state.phoneNum)){
            return Toast.fail('请输入正确的手机号', 1.5)
        }
        if (this.state.code === '') {
            return Toast.fail('请输入验证码', 1.5)
        }
        this.state.loginDisable = true
        let data = {
            mobile:this.state.phoneNum,
            code:this.state.code
        }
        if (window.localStorage.getItem('userId') && window.localStorage.getItem('userId').length === 32) {
            data.temp_user = window.localStorage.getItem('userId')
        }
        if (window.localStorage.getItem('inviter_Id')) {
            data.inviter_id = window.localStorage.getItem('inviter_Id')
        }
        if (window.localStorage.getItem('source')) {
            data.source = window.localStorage.getItem('source')
        }
        axios.post(`/users/v3`,data).then((res) => {
            console.log(res)
            if (res.data) {
                this.state.loginDisable = false
                window.localStorage.setItem('userId', res.data.data.user_id)
                window.localStorage.setItem('dialog', false)
                if(res.data.data.is_new){
                    window.localStorage.setItem('isNew', true)
                }
                this.props.history.goBack()
            } else {
                this.state.loginDisable = false
                this.state.code = ''
                return Toast.fail('登录失败', 1.5)
            }
        }).catch(e => {
            clearInterval(this.timer);
            this.state.loginDisable = false
            this.state.code = ''
            let mes = ''
            switch (e.data.code) {
                case 1905:
                    mes = `验证码错误`
                    break
                default:
                    mes = '登录失败，请重试'
                    return
            }
            return Toast.fail(mes, 1.5)
        })
    }
    render() {
        return (
            <div className="loginWrapper">
                <img className="back" src={require("../../assets/v2/back-black.png")} onClick={()=>{this.props.history.goBack()}}/>
                <div className="content-wrapper">
                    <p className="title">验证码登录</p>
                    <div className="box">
                        <input style={{border:'none'}} className="input" maxLength="11" placeholder="请输入手机号" value={this.state.phoneNum} onChange={(e) => {
                            this.setState({phoneNum: e.target.value})
                        }}/>
                        <img width="12" height="12" className="close" src={require("../../assets/v2/close_tiny.png")}/>
                    </div>
                    <div className="codebox">
                        <input style={{border:'none'}} className="input" maxLength="6" placeholder="输入短信验证码" value={this.state.code} onChange={(e) => {
                            this.setState({code: e.target.value})
                        }}/>
                        <span className="getCode" onClick={this.TCaptcha.bind(this)}>{this.state.dlgTipTxt}</span>
                    </div>
                    <div className="btn" onClick={this.login.bind(this)}>确定</div>
                </div>
            </div>
        )
    }
}