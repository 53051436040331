import React from 'react'
import {Toast} from 'antd-mobile';
import {bundles} from './bundle'
export default class Call extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        Toast.loading('', 1);
        const Assistant = window.localStorage.getItem('bundleId')
        if(Assistant){
            window.location.href = `${Assistant}://installCertificate`
        }
    }
    render() {
        return (
            <div style={{
                flexDirection: 'column',
                background: '#fff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: '100vh'
            }}>
                <img src={require('../../assets/v2/attention.png')}/>
                <p style={{marginTop: 15, fontSize: 14}}>若App跳转失败，请手动打开试玩助手。</p>
            </div>
        )
    }
}
