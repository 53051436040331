import React from 'react'
import {Toast} from 'antd-mobile';
import {bundles} from './bundle'
export default class Call extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {

    }

    setting(){
        window.location.href = 'https://dqy-download.oss-cn-beijing.aliyuncs.com/dqy.mobileprovision'
    }

    render() {
        return (
            <div style={{
                flexDirection: 'column',
                background: '#fff',
                display: 'flex',
                justifyContent: 'flexStart',
                alignItems: 'center',
                width: '100vw',
                height: '100vh'
            }}>
                <img style={{width:'80%',marginTop:80}} src={require('../../assets/v2/tip.png')}/>
                <img style={{height:45,marginTop:20}} src={require('../../assets/v2/btn.png')} onClick={()=>{this.setting()}}/>
            </div>
        )
    }
}
