import React, {useEffect, useState} from 'react'
import './attempt.scss'
import ListItem from './listItem'
import copy from "copy-to-clipboard/index";
import axios from "../../netUtils/axios";
import {Toast} from "antd-mobile/lib/index";
import Trust from '../../components/trust/trust'
import Download from "../../components/download";

const app = ['app', 'enterprise']
const rate = [1, 0]
const listDetail = [{
    keyword: '微信',
    num: 100,
    reward: 8,
    app: {
        image_url: require('../../assets/v2/news_fill.png')
    }
}, {
    keyword: '淘宝',
    num: 234,
    reward: 7,
    app: {
        image_url: require('../../assets/v2/mine_fill.png')
    }
}, {
    keyword: '淘宝',
    num: 234,
    reward: 7,
    app: {
        image_url: require('../../assets/v2/mine_fill.png')
    }
}, {
    keyword: '淘宝',
    num: 234,
    reward: 7,
    app: {
        image_url: require('../../assets/v2/mine_fill.png')
    }
}, {
    keyword: '淘宝',
    num: 234,
    reward: 7,
    app: {
        image_url: require('../../assets/v2/mine_fill.png')
    }
}, {
    keyword: '淘宝',
    num: 234,
    reward: 7,
    app: {
        image_url: require('../../assets/v2/mine_fill.png')
    }
}, {
    keyword: '淘宝',
    num: 234,
    reward: 7,
    app: {
        image_url: require('../../assets/v2/mine_fill.png')
    }
}, {
    keyword: '淘宝',
    num: 234,
    reward: 7,
    app: {
        image_url: require('../../assets/v2/mine_fill.png')
    }
}, {
    keyword: '淘宝',
    num: 234,
    reward: 7,
    app: {
        image_url: require('../../assets/v2/mine_fill.png')
    }
}]
let Attempt = (props) => {
    const [list, setList] = useState([])
    const [install, setInstall] = useState(false)
    const [installE, setInstallE] = useState(false)
    const [install_sec, setInstall_sec] = useState(false);
    const [trust, setTrust] = useState(false);
    const [installAdr, setInstallAdr] = useState(false)
    const [type, setType] = useState(null)
    const [inviterId, setInviterId] = useState(null);
    const [userAgent, setUserAgent] = useState('ios')
    useEffect(() => {
        const u = navigator.userAgent;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 || u.indexOf('Adr') > -1; //android终端或者uc浏览器
        if (window.localStorage.getItem('inviter_Id')) {
            setInviterId(window.localStorage.getItem('inviter_Id'))
        }
        if (isAndroid) {
            setUserAgent('Android')
            window.localStorage.setItem('ost', 'Android')
        } else {
            setUserAgent('ios')
            window.localStorage.setItem('ost', 'ios')
        }
        axios.get(`/launches/list`).then((res) => {
            if (res.data === '') {
                setList(listDetail)
            } else {
                setList(res.data)
            }
        })
    }, [])

    function click() {
        if (userAgent === 'ios') {
            let type = random(app, rate)
            if (type === 'app') {
                setInstall(true)
            } else {
                setInstallE(true)
            }
        } else {
            setInstallAdr(true)
        }
    }

    function close_(e) {
        setInstall_sec(false)
    }
    function changeApp() {
        setInstallE(true)
    }

    function close(e) {
        setInstall(false)
    }

    function closeE() {
        setInstallE(false)
    }

    function closeA() {
        setInstallAdr(false)
    }

    function random(arr1, arr2) {
        var sum = 0
        var factor = 0
        var random = Math.random()
        for (var x = arr2.length - 1; x >= 0; x--) {
            sum += arr2[x]
        }
        random *= sum
        for (var t = arr2.length - 1; t >= 0; t--) {
            factor += arr2[t]
            if (random <= factor) {
                return arr1[t]
            }
        }
        return null
    }

    function download() {
        let userId = window.localStorage.getItem('userId')
        let key = localStorage.getItem('key')
        if (userId) {
            copy(`%%cq=${userId}-${key}%%`)
        }
        window.location.href = 'itms-services://?action=download-manifest&url=https://statics.diaoqianyaner.com.cn/3.5.1/manifest_3.5.1.plist'
        setInstallE(false)
        setInstall_sec(true)
    }

    function downloadAdr() {
        let userId = window.localStorage.getItem('userId')
        let key = localStorage.getItem('key')
        if (userId) {
            copy(`%%cq=${userId}-${key}%%`)
        }
        window.location.href = 'https://dyq3.oss-cn-hangzhou.aliyuncs.com/diaoqianyaner.apk'
        setInstallAdr(false)
    }

    function trust_(flag) {
        if (flag) {
            window.location.href = 'http://dqy-download.oss-cn-beijing.aliyuncs.com/dqy.mobileprovision'
            setTrust(true)
        } else {
            return false
        }

    }

    function openApp() {
        if (trust) {
            window.location.href = 'srshare://'
        } else {
            Toast.info('请先信任助手!', 1.5);
        }

    }

    return (
        <div className="attemptWrapper">
            {/* <div className="header">
                <div className="nav">
                    <img className="back" src={require("../../assets/v2/back-black.png")} onClick={() => {
                        props.history.goBack()
                    }}/>

                    <p className="feedback" onClick={() => {
                        window.location.href = "https://chatlink.mstatik.com/widget/standalone.html?eid=135780"
                    }}>在线客服</p>
                </div>
                <div className="intro">
                    <p className="title">试玩赚钱</p>
                    <p className="tiny">下载体验 App，日赚30元</p>
                </div>

            </div> */}
            <div className="attemptContent">
                <div className="normal">
                    {/* <div className="title">标准任务</div>
                    <div className="step">下载应用 / 试玩3分钟 / 领取现金</div> */}
                    <ListItem list={list} handleClick={()=>{}}/>
                </div>
            </div>
            {
                install ? <Download close={close.bind(this)} inviterId={inviterId} change={changeApp.bind(this)}/> : null
            }
            {installE ? <div className="attemptmask">
                <div className="animated zoomIn dialog">
                    <div className='ex'>
                        <img onClick={(e) => {
                            closeE(e)
                        }} className="close" src={require('../../assets/v2/close_tiny.png')}/>
                        <img style={{marginTop: 26}} width={75} height={75} src={require('../../assets/v2/icon.png')}/>
                        <p className='p1'>下载闪赚试玩助手</p>
                        {!!inviterId?<p className='p2'>{`${inviterId} 邀请你试玩提现`}</p>:<p className='p2'>立即下载，试玩提现</p>}
                        <div className="btn" onClick={download.bind(this)}>立即下载</div>
                    </div>
                </div>
            </div> : null}
            {install_sec ?
                <Trust close_={close_.bind(this)} trust_={trust_.bind(this)} openApp={openApp.bind(this)}/> : null}
            {
                installAdr
                    ? <div className="attemptmask">
                        <div className="animated zoomIn dialog">
                            <div className='ex'>
                                <img onClick={(e) => {
                                    closeA(e)
                                }} className="close" src={require('../../assets/v2/close_tiny.png')}/>
                                <img style={{marginTop:26}} width={75} height={75} src={require('../../assets/v2/icon.png')}/>
                                <p className='p1'>下载闪赚试玩助手</p>
                                {!!inviterId?<p className='p2'>{`${inviterId} 邀请你试玩提现`}</p>:<p className='p2'>立即下载，试玩提现</p>}
                                <div className="btn" onClick={downloadAdr.bind(this)}>立即下载</div>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    );
}

export default Attempt;
