/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./home.scss";
import axios from "../../netUtils/axios";
import { Toast, Carousel, NoticeBar } from "antd-mobile";
import Download from "../../components/download";
import copy from "copy-to-clipboard/index";
import Trust from "../../components/trust/trust";
import userList from "./userList";
import Attempt from "../Attempt/attempt";

const app = ["app", "enterprise"];
const rate = [1, 0];
const taskItem = [
  {
    img: require("../../assets/v3/tospeed.png"),
    text: "极速任务",
  },
  {
    img: require("../../assets/v3/video.png"),
    text: "视频红包",
  },
  {
    img: require("../../assets/v3/chatbox.png"),
    text: "ChatGPT",

  },
  {
    img: require("../../assets/v3/signIn.png"),
    text: "现金签到",
  },
];
let Home = (props) => {
  const [isNew, setIsNew] = useState(false);
  const [mask, setMask] = useState("");
  const [noticehead, setNoticehead] = useState(false);
  const [amount, setAmount] = useState(0);
  const [score, setScroe] = useState(0);
  const [install, setInstall] = useState(false);
  const [installE, setInstallE] = useState(false);
  const [installAdr, setInstallAdr] = useState(false);
  const [install_sec, setInstall_sec] = useState(false);
  const [inviterId, setInviterId] = useState(null);
  const [source, setSource] = useState(null);
  const [trust, setTrust] = useState(false);
  const [userAgent, setUserAgent] = useState("ios");
  const [appInfo, setAppInfo] = useState({});
  useEffect(() => {
    axios.get(`ios_package_info`).then((res) => {
      setAppInfo(res.data);
      localStorage.setItem("key", res.data.key);
    });
    changeUserType();
    let type = getQueryString();
    let Inviter = getQueryString("userId");
    let Source = getQueryString("source");
    const u = navigator.userAgent;
    const isAndroid =
      u.indexOf("Android") > -1 ||
      u.indexOf("Linux") > -1 ||
      u.indexOf("Adr") > -1; //android终端或者uc浏览器
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
      setUserAgent("Android");
      window.localStorage.setItem("ost", "Android");
      let url = `http://invite.chuangqish.cn/`;
      if (Inviter) {
        url = `${url}?userId=${Inviter}`;
      }
      if (Source) {
        url = `${url}&source=${Source}`;
      }
      return (window.location.href = url);
    } else {
      setUserAgent("ios");
      window.localStorage.setItem("ost", "ios");
    }
    if (
      Inviter &&
      Inviter !== "" &&
      Inviter !== undefined &&
      Inviter !== "null" &&
      Inviter !== "undefined"
    ) {
      window.localStorage.setItem("inviter_Id", Inviter);
      setInviterId(getQueryString("userId"));
    }
    setSource(getQueryString("source"));
    if (Source && Source !== "" && Source !== undefined && Source !== "null") {
      window.localStorage.setItem("source", Source);
    }
    window.localStorage.setItem("type", type);
    if (window.localStorage.getItem("amount")) {
      setAmount(window.localStorage.getItem("amount"));
    }
    // if (window.localStorage.getItem('dialog') === null) {
    //     setMask(true)
    // } else {
    //     setMask(false)
    // }
    if (
      window.localStorage.getItem("userId") &&
      window.localStorage.getItem("userId").length !== 32
    ) {
      let userId = window.localStorage.getItem("userId");
      axios.get(`users/${userId}/menu`).then((res) => {
        setAmount(res.data.income_available);
      });
      axios.get(`users/${userId}/statistics`).then((res) => {
        setScroe(res.data.score_available);
      });
    } else if (
      window.localStorage.getItem("userId") &&
      window.localStorage.getItem("userId").length === 32
    ) {
      setAmount(88);
      window.localStorage.setItem("isNew", false);
      window.localStorage.setItem("amount", 88);
      window.localStorage.setItem("dialog", false);
    }
  }, []);

  function download() {
    window.ga("send", "event", "下载马甲包", "click", "页面浏览");
    let userId = window.localStorage.getItem("userId");
    let key = localStorage.getItem("key");
    if (userId) {
      copy(`%%cq=${userId}-${key}%%`);
    }
    window.location.href = appInfo.app_download_url;
    setInstallE(false);
    setInstall_sec(true);
  }

  function downloadAdr() {
    let userId = window.localStorage.getItem("userId");
    let key = localStorage.getItem("key");
    if (userId) {
      copy(`%%cq=${userId}-${key}%%`);
    }
    window.location.href =
      "https://dyq3.oss-cn-hangzhou.aliyuncs.com/diaoqianyaner.apk";
    setInstallAdr(false);
  }

  function uuid(len, radix) {
    var chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
        ""
      );
    var uuid = [],
      i;
    radix = radix || chars.length;

    if (len) {
      // Compact form
      for (i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
    } else {
      // rfc4122, version 4 form
      var r;

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
      uuid[14] = "4";

      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
        }
      }
    }

    return uuid.join("");
  }

  function route(path) {
    if (path === "register") {
      window.localStorage.setItem("title", "绑定手机号");
    } else if (path === "login") {
      window.localStorage.setItem("title", "验证码登录");
    }
    if (inviterId) {
      props.history.push(`${path}/${inviterId}`);
    } else {
      props.history.push(`${path}/ `);
    }
  }

  function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var params = window.location.href.split("?");
    if (params[1]) {
      var r = params[1].match(reg);
    } else {
      var r = window.location.search.substr(1).match(reg);
    }
    if (r != null) return decodeURI(r[2]);
    return null;
  }

  function Install(value) {
    changeUserType();
    if (userAgent === "ios") {
      if (value === "试玩赚钱") {
        props.history.push("attempt");
      } else {
        let type = random(app, rate);
        if (type === "app") {
          setInstall(true);
        } else {
          setInstallE(true);
        }
      }
    } else {
      if (value === "试玩赚钱") {
        props.history.push("attempt");
      } else {
        setInstallAdr(true);
      }
    }
  }

  function faker() {
    if (userAgent === "ios") {
      setIsNew(true);
      setMask(false);
    } else {
      route("login");
    }
  }

  function changeUserType() {
    // 临时ID

    let postData = {};
    if (window.localStorage.getItem('userId')) {
      postData.temp_user = window.localStorage.getItem('userId')
    } else {
      let tempId = uuid(32, 16);
      postData.temp_user = tempId
      window.localStorage.setItem("userId", tempId);
    }
    if (getQueryString("userId")) {
      postData.inviter_id = getQueryString("userId");
    }
    if (getQueryString("source")) {
      postData.source = getQueryString("source");
    }
    axios.post(`users/temp_user`, postData).then((res) => {
      setIsNew(false);
      setAmount(88);
      window.localStorage.setItem("isNew", false);
      window.localStorage.setItem("amount", 88);
      window.localStorage.setItem("dialog", false);
    });
  }
  function random(arr1, arr2) {
    var sum = 0;
    var factor = 0;
    var random = Math.random();
    for (var x = arr2.length - 1; x >= 0; x--) {
      sum += arr2[x];
    }
    random *= sum;
    for (var t = arr2.length - 1; t >= 0; t--) {
      factor += arr2[t];
      if (random <= factor) {
        return arr1[t];
      }
    }
    return null;
  }

  function close_(e) {
    e.stopPropagation();
    setInstall_sec(false);
  }

  function close() {
    setInstall(false);
  }

  function closeA() {
    setInstallAdr(false);
  }

  function trust_(flag) {
    if (flag) {
      window.location.href =
        "http://dqy-download.oss-cn-beijing.aliyuncs.com/dqy.mobileprovision";

      setTrust(true);
    } else {
      return false;
    }
  }

  function openApp() {
    if (trust) {
      window.location.href = appInfo.schema + "://";
    } else {
      Toast.info("请先完成“信任助手”", 1.5);
    }
  }

  const downloadApp = () => {
    let userId = window.localStorage.getItem("userId");
    let key = localStorage.getItem("key");
    if (userId) {
      copy(`%%cq=${userId}-${key}%%`);
    }
    if (appInfo.package_type === 0) {
      return props.history.push('/iosPackage')
    }
    if (appInfo.package_type === 1) {
      return props.history.push({
        pathname: `/TF/${appInfo.app_download_url}`,
      });
    }
    if (appInfo.package_type === 2) {
      return setInstallE(true);
    }
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {
      // 判断是否为微信浏览器环境
      if (/MicroMessenger/i.test(navigator.userAgent)) {
          setVisible(true);
      }
  }, []); // 仅在组件挂载生命周期时执行一次

  return (
    <div className="container">
            {visible && (
                <div className='cover-main-bj' >
                    <img className='cover-main-openInSafari' src={require('../../assets/v3/openInSafari.png')} />
                </div>
            )}
      <div style={{ position: "fixed", top: 0, width: "100%" }}>
      </div>
      <div className="head" onClick={downloadApp}>
        <div className="head_itemBox">
          {taskItem.map((item, i) => (
            <div className="head_itemBox_item" key={i}>
              <img
                className={item.className ? item.className : ""}
                src={item.img}
              />
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>

      {}

      <div className="main" onClick={downloadApp}>
        <img src={require("../../assets/v3/可领取奖励 312 元.png")} />
        <Carousel
          className="userList"
          vertical
          dots={false}
          dragging={false}
          swiping={false}
          autoplay
          infinite
        >
          {userList.map((item, i) => (
            <div className="v-item" key={i}>
              <span>
                <img width="18" src={item.headPic} alt="" />
                {item.nickName} 获得12元试玩奖励
              </span>
            </div>
          ))}
        </Carousel>

        {/* 任务列表 */}
        <Attempt />
      </div>
      <div className="footer" onClick={downloadApp}>
        {/* <img src={appInfo.img_url} /> */}

        <div className="left" >
          <img src={require('../../assets/v3/appstore.png')} />
          <div className="footer_text">
            <p>
              下载 <span>「试玩助手」</span>
              {/* <span>{appInfo.app_name}</span> */}
            </p>
            <p>请允许“使用网络”权限</p>
          </div>
        </div>


        <img
          className="animated tada"
          src={require("../../assets/v3/download.png")}
        />
      </div>
      {noticehead ? null : (
        <div
          style={{
            width: "100%",
            height: 1,
            background: "#f3f3f3",
            marginTop: 18,
            marginBottom: 18,
          }}
        ></div>
      )}
      {mask ? (
        <div className="mask">
          <div className="dialog">
            <img
              className="dialog-img"
              src={require("../../assets/v2/select.png")}
            />
            <p
              style={{
                fontWeight: 500,
                fontSize: 18,
                color: "#333333",
                marginBottom: 15,
              }}
            >
              登录立享
              <span
                style={{
                  fontWeight: 500,
                  fontSize: 18,
                  color: "#FF4373",
                }}
              >
                新手红包
              </span>
            </p>
            <p
              style={{
                fontWeight: 13,
                color: "#999999",
                marginBottom: 22,
              }}
            >
              新用户登录即可获得新手红包
            </p>
            <div
              className="new"
              onClick={() => {
                faker();
              }}
            >
              立即领取
            </div>
            {userAgent === "ios" ? (
              <div
                className="old"
                onClick={() => {
                  route("login");
                }}
              >
                立即登录
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {isNew ? (
        <div className="mask">
          <div className="animated zoomIn _dialog">
            <img
              onClick={() => {
                changeUserType();
              }}
              src={require("../../assets/v2/new_alert.png")}
            />
          </div>
        </div>
      ) : null}
      {install ? (
        <Download close={close.bind(this)} inviterId={inviterId} {...props} />
      ) : null}
      {installE ? (
        <div className="attemptmask">
          <div className="animated zoomIn dialog_">
            <div
              className="app-info"
              style={{ width: "100%" }}
              onClick={() => {
                download();
              }}
            >
              <img src={appInfo.img_url} />
              <span className="name">{appInfo.app_name}</span>
            </div>
          </div>
        </div>
      ) : null}
      {install_sec ? (
        <Trust
          close_={close_.bind(this)}
          trust_={trust_.bind(this)}
          openApp={openApp.bind(this)}
          signer={appInfo.signer}
        />
      ) : null}
      {installAdr ? (
        <div className="attemptmask">
          <div className="animated zoomIn dialog">
            <div className="ex">
              <img
                onClick={(e) => {
                  closeA(e);
                }}
                className="close"
                src={require("../../assets/v2/close_tiny.png")}
              />
              <img
                style={{ marginTop: 26 }}
                width={75}
                height={75}
                src={require("../../assets/v2/icon.png")}
              />
              <p className="p1">下载闪赚试玩助手</p>
              {!!inviterId ? (
                <p className="p2">{`${inviterId} 邀请你试玩提现`}</p>
              ) : (
                <p className="p2">立即下载，试玩提现</p>
              )}
              <div className="btn" onClick={downloadAdr.bind(this)}>
                立即下载
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Home;
