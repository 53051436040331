import React, { useEffect, useState } from "react";
import axios from "../../netUtils/axios";

import copy from "copy-to-clipboard/index";

import "./index.scss";

const index = (props) => {
  const [appInfo, setAppInfo] = useState({});
  useEffect(() => {
    axios.get(`ios_package_info`).then((res) => {
      setAppInfo(res.data);
    });
  }, []);
  return (
    <div
      id="TF"
      onClick={() => {
        let userId = window.localStorage.getItem("userId");
        let key = localStorage.getItem("key");
        copy(`%%cq=${userId}-${key}%%`);
      }}
    >
      <header>
        <img
          onClick={() => {
            props.history.go(-1);
          }}
          height="20"
          src={require("../../assets/v2/back-black.png")}
        />
        <span>下载试玩助手</span>
      </header>

      <img
        onClick={() => {
          window.location.href = props.location.pathname.slice(4);
        }}
        src={require("../../assets/v3/Frame 206.png")}
      />
      <div
        className="tfbj"
        onClick={() => {
          window.location.href = props.location.pathname.slice(4);
        }}
      >
        <div className="box1">
          <img src={appInfo.img_url} />
          <div className="box2">
            <span>{appInfo.app_name}</span>
            <span style={{ fontSize: 11, color: 'rgba(65, 75, 90, 0.66)' }}>下载后允许权限即可开始</span>
          </div>
        </div>
      </div>
      <img
        onClick={() => {
          let userId = window.localStorage.getItem("userId");
          let key = localStorage.getItem("key");
          if (userId) {
            copy(`%%cq=${userId}-${key}%%`);
          }
          window.location.href = this.state.appInfo.schema + "://";
        }}
        src={require("../../assets/v3/Frame 208.png")}
      />
    </div>
  );
};

export default index;
