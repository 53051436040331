export const bundles = new Map([
    [1,['id1457059643','CQCheatingDetection']],
    [2,['id1457072239','ComCvCarShare']],
    [3,['id1457329825','comFengshuiluopanCom']],
    [4,['id1456340193','comFababiAnitoys']],
    [5,['id1458753978','comCnOcj']],
    [6,['id1462456786','comXianfuRu']],
    [7,['id1460226479','comzuiQiangyouxipop']],
    [8,['id1447335635','comAKWebDesignerSlidingSanta']],
    [9,['id1462860629','comfamousappsparethesuggested']],
    [10,['id1463837842','compsStarios']],
    [11,['id1463601352','comkilovoltampapp']],
    [12,['id1466759308','comtaskifylistsyou']],
    [13,['id1465866342','comjoejoeshukepool']],
    [14,['id1465854149','comeyuZHUshoupop']],
    [15,['id1475456252','comGDQCDrums']],
    [16,['id1472456999','PTIProjectItem']],
    [17,['id1474059275','KFPKongFuProject']],
    [18,['id1476127535','SUBSubjectItem']],
    [19,['id1475445329','DANDance']],
    [20,['id1475447718','comljxbsfamily']],
    [21,['id1476259125','commaomishewww']],
    [22,['id1482706235','comshangzezhushouCollectablesProject']],
    [23,['id1478726092','comcggCartoon']],
    [24,['id1483172796','wwwkuaizikecom']],
    [25,['id1484466955','cntongvictorye']],
    [26,['id1484779528','comCSHLDraw']],
    [27,['id1487427892','compieceClothes']],
    [28,['id1487592464','aizhangdiangovcn']],
    [29,['id1484615670','comhuajiangwww']],
    [30,['id1479199432','comLocalDiaryLocalDiary']],
    [31,['id1477975451','comruijinmeirui']],
    [32,['id1479180529','comshuoliulishuo']],
    [33,['id1330573036','comjackyugamespiraterunaway']],
    [34,['id1492112161','comgameFSaveToHang']],
    [35,['id1484778129','comcccSweetDream12']],
    [36,['id1492443937','cntaoTCApp']],
    [37,['id1492562369','comHongCYStore']],
    [38,['id1493056597','comAntigueProjectfandieAntique']],
    [39,['id1494090783','oubsk38chujianbaitai']],
    [40,['id1494657260','Forestcakecom']],
    [41,['id1494114228','comqinghuachuju']],
    [42,['id1493049547','comsingsetitevey']],
    [43,['id1493046678','CeramicCeramicProduct']],
    [44,['id1495012961','comlifegoodAForFourteenth']],
    [45,['id1495138901','zhengji9868039namokongjian']],
    [46,['id1498357687','comyuoSchoolBuyBooks']],
    [47,['default','srshare']]
])