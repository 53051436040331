export default [
    {
        headPic:require('../../assets/v3/头像/1.jpeg'),
        nickName:'浪漫爵士^0^'
    },
    {
        headPic:require('../../assets/v3/头像/2.jpeg'),
        nickName:'啊全'
    },
    {
        headPic:require('../../assets/v3/头像/3.jpeg'),
        nickName:'Healer'
    },
    {
        headPic:require('../../assets/v3/头像/4.jpeg'),
        nickName:'是我啊'
    },
    {
        headPic:require('../../assets/v3/头像/5.jpeg'),
        nickName:'猫咪改吃素'
    },
    {
        headPic:require('../../assets/v3/头像/6.jpeg'),
        nickName:'小诺诺'
    },
    {
        headPic:require('../../assets/v3/头像/7.jpeg'),
        nickName:'笑丶扯痛ㄋ嘴角'
    },
    {
        headPic:require('../../assets/v3/头像/8.jpeg'),
        nickName:'家有宝贝'
    },
    {
        headPic:require('../../assets/v3/头像/9.jpeg'),
        nickName:'老顽童'
    },
    {
        headPic:require('../../assets/v3/头像/10.jpeg'),
        nickName:'晚夏'
    },
    {
        headPic:require('../../assets/v3/头像/11.jpeg'),
        nickName:'🌸  lulu_'
    },
    {
        headPic:require('../../assets/v3/头像/12.jpeg'),
        nickName:'💦 悠悠 然  自自 在💦🔥'
    },
    {
        headPic:require('../../assets/v3/头像/13.jpeg'),
        nickName:'Zzhh'
    },
    {
        headPic:require('../../assets/v3/头像/14.jpeg'),
        nickName:'Andy'
    },
    {
        headPic:require('../../assets/v3/头像/15.jpeg'),
        nickName:'小惠'
    },
    {
        headPic:require('../../assets/v3/头像/16.jpeg'),
        nickName:'海阔天空'
    },
    {
        headPic:require('../../assets/v3/头像/17.jpeg'),
        nickName:'柑橘与柠檬啊'
    },
    {
        headPic:require('../../assets/v3/头像/18.jpeg'),
        nickName:'碌碌无为'
    },
    {
        headPic:require('../../assets/v3/头像/19.jpeg'),
        nickName:'愚者'
    },
    {
        headPic:require('../../assets/v3/头像/20.jpeg'),
        nickName:'顺其自然'
    },
]